<template>
    <div>
        <div class="top">
          <div class="top_text">
          {{ this.data6.data.course_name }}
          </div>
          <div class="top_text2">
            课程有效期：{{ this.data6.data.course_time }}
          </div>
          <div class="yuandian">
            
          </div>
          <div class="yuandian2">
            
          </div>
          <div class="miaos">
            {{ this.data6.data.remarks }}
            
          </div>
          <div class="dianh">
            联系电话：{{ this.data6.data.mobile  }}
            <!-- 联系电话：18151114091 -->
          </div>
          <!-- <img class="erweitu" :src="erwei" alt=""> -->
          <img class="mingshi" src="../assets/img_title@2x.png" alt="">
          <img src="../assets/gkdd_h5_img_haibao@2x.png" class="bg_img" alt="">
        <!-- {{ order_id }} -->
        </div>

        <!-- {{ order_id }} -->
        <div class="bottom_kuai">
    </div>
  <div class="bottom_xin">
    <div class="bottom_left">
          <div class="price_font">￥{{ this.data6.data.course_price  }}</div>/{{ this.data6.data.course_time }}
        </div>
        <!-- {{ this.data9 }} -->
        <div v-if="buy==0" class="bao_button" @click="Check1">立即报名</div>
        <div v-else class="bao_button2" >已报名</div>
  </div>
        
        <div>
          <!-- order_id{{ order_id }}<br>
          mingzi{{ mingzi }}<br>
          dianhua{{ dianhua }}<br>
          value{{ value }}<br>
          jiage{{ jiage }}<br>
          shijian{{ shijian }}<br>
          erwei{{ erwei }}<br> -->
          <!-- <img :src="erwei" style="width: 30px;height: 50px;" alt=""> -->
          <!-- {{ data6 }} -->
        </div>
        <!-- <pay-footer></pay-footer> -->
    </div>
   
    
</template>
<script>
import {
    setToken,getToken
} from '../util/auth'
import payFooter from "@/components/common/footerPay";
import { Checkbox, Toast } from 'vant';
import request from "..//plugin/request";
import wx from 'weixin-js-sdk'
     export default {
      components: {
   
    payFooter
  },
            name:'applyText',
             data(){
                  return {
                    order_id:"",
                    jiage:"",
                    shijian:"",
                    value:"",
                    mingzi:"",
                    dianhua:"",
                    erwei:"",
                    token:"",
                    data6:"",
                    buy:0,
                    data9:""
                 }
          },
 computed: {
   
  },
  mounted(){
    document.title = "课程详情"
  },
  beforeCreate(){
    document.title = "课程详情"
  },
     created(){

    document.title = "课程详情"
    const params = new URLSearchParams(location.search);
    this.order_id = params.get('order_id'); 
    this.shijian = params.get('shijian'); 
    this.value = params.get('value'); 
    this.jiage = params.get('jiage'); 
    this.mingzi = params.get('mingzi'); 
    this.dianhua = params.get('dianhua'); 
    this.erwei = params.get('erwei'); 
    this.token = params.get('token'); 
    if(this.token){
      setToken(this.token)
    }else{
      setToken("")
    }
    
    this.buy = params.get('buy'); 
    this.order_text()
    this.buyyn()
  },
  methods:{
   async order_text(){
    
      this.data6=await request.post('/app/courseShow',{order_id:this.order_id})
      
    },
    async buyyn(){
    
    this.data9=await request.post('/app/courseOrderJudge',{order_id:this.order_id})
    if(this.data9.data==1){
      this.buy=1
    }else if(this.data9.data==0){
      this.buy=0
    }else{
      this.buy=0
    }
    console.log(this.buy,999)
  },
    Check1(){
      console.log(3333)
      if(this.token){

        wx.miniProgram.navigateTo({
              url: '/pages/home/classpay?order_id='+this.order_id, //小程序地址
             })
      }else{
        wx.miniProgram.navigateTo({
              url: '/pages/index/denglu?order_id='+this.order_id, //小程序地址
             })
      }
    }
  }
}
</script>
<style scoped>
.bottom_xin{
  position: fixed;
  bottom: 0px;
}
.yuandian{
  width: 3px;
height: 3px;

background: #B1B9F8;
border-radius: 212px 212px 212px 212px;
position: absolute;
left: 60px;
top: 445px;
}
.yuandian2{
  width: 3px;
height: 3px;

background: #B1B9F8;
border-radius: 212px 212px 212px 212px;
position: absolute;
left: 60px;
top: 490px;
}
.mingshi{
  width: 266px;
  height: 19px;
  position: absolute;
left: 54px;
top: 405px;
}
.erweitu{
  width: 95px;
height: 95px;
/* background: #F5F5F5; */
border-radius: 6px 6px 6px 6px;
position: absolute;
left: 49px;
top: 410px;
}
.dianh{
  font-weight: 400;
font-size: 14px;
color: #3D3D3D;
position: absolute;
left: 80px;
top: 480px;

}
.miaos{
  width: 240px;
  font-weight: 400;
font-size: 14px;
color: #3D3D3D;
position: absolute;
left: 80px;
top: 435px;
}
.top_text2{
  font-weight: 500;
font-size: 16px;
color: #5D6382 ;
position: absolute;
text-align: center;
left: 122px;
top: 104px;
}
.top{
  position: relative;
}
.top_text{
  font-weight: 700;
  font-size: 24px;
  color: #352A8A;
  text-align: center;
  margin-top: 29px;
  width: 275px;
  height: 60px;
  position: absolute;
  left: 50px;
}
.bottom_kuai{
  width: 375px;
  height: 64px;
  background: #3947B4 ;
  margin-top: -15px;
}
.bg_img{
    width: 375.65px;
    height: 564.94px;
}
.bottom_left{
    height: 36px;
    font-weight: 400;
font-size: 20px;
color: #AEAEAE;
display: flex;
line-height: 36px;
margin-left: 15px;
margin-top: 14px;
}
.price_font{
    font-weight: 400;
font-size: 26px;
color: #3D3D3D;
line-height: 36px;
}
.bao_button{
    width: 137px;
height: 48px;
background: #3074FF;
border-radius: 10px 10px 10px 10px;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
line-height: 48px;
margin-top: -42px;
    text-align: center;
    margin-left: 223px;
}
.bao_button2{
    width: 137px;
height: 48px;
/* background: #3074FF; */
background: gray;
border-radius: 10px 10px 10px 10px;
font-weight: 400;
font-size: 16px;
color: #FFFFFF;
line-height: 48px;
margin-top: -42px;
    text-align: center;
    margin-left: 223px;
}
</style>
