import { render, staticRenderFns } from "./buyclassh5.vue?vue&type=template&id=82750638&scoped=true&"
import script from "./buyclassh5.vue?vue&type=script&lang=js&"
export * from "./buyclassh5.vue?vue&type=script&lang=js&"
import style0 from "./buyclassh5.vue?vue&type=style&index=0&id=82750638&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82750638",
  null
  
)

export default component.exports